import React, { useState, useEffect, useContext } from 'react';
import { Alert, Snackbar, Button, Pagination, CircularProgress, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
	createDataSpaceSchemaWithName,
	deleteDataSpaceFromBackend,
	deleteDataSpaceNew,
	getDataSpaces,
} from '../../api_helper/api';
import { TabMenu } from 'primereact/tabmenu';
import ListItem from './ListItem';
import ConfirmationDialog from './ConfirmationDialog';
import './MyDataSpacesHome.css';
import { trackEvent } from '../../utils/eventTracking';
import { UserProfileContext } from '../../UserProfileContext';
import MyDataSpacesViewContext from './context/MyDataSpacesViewContext';
import CreateEditDataSpaceContext from './context/CreateEditDataSpaceContext';
import NBALogo from '../../assets/img/NBALogoSquare.png';
import NHLLogo from '../../assets/img/NHLLogoSquare.png';
import MLBLogo from '../../assets/img/MLBLogoSquare.png';
import NFLLogo from '../../assets/img/NFLLogoSquare.png';
import PageContext from '../../context/PageContext';
import DataSpacePreviewDialog from '../community_dataSpaces/DataSpacePreviewDialog';
import CommunityDataSpacesContext from '../../context/CommunityDataSpacesContext';
import ShareDataSpaceDialog from './ShareDataSpaceDialog';
import ShareToCommunity from '../../assets/img/ShareToCommunity.png';
import CreateDataSpace from '../../assets/img/CreateDataSpace.png';
import SearchInput from './SearchInput';

const useStyles = makeStyles({
	pageWrapper: {
		width: '100%',
		maxWidth: '60rem',
		margin: '3rem auto',
		paddingRight: '1rem',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		'& a:hover': {
			cursor: 'pointer',
		},
	},
	myDataSpacesWrapper: {
		width: '100%',
		maxWidth: '60rem',
		'& a:hover': {
			cursor: 'pointer',
		},
	},
	communityDataSpacesLink: {
		width: '100%',
		margin: '4rem 0',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		'& a': {
			cursor: 'pointer',
		},
	},
	button: {
		color: 'white',
	},
	featuredCommunityDataSpace: {
		'&:hover': {
			cursor: 'pointer',
		},
	},
});
// function for adding encharge tracking

export default function MyDataSpacesHome(props) {
	const classes = useStyles();

	const pageContext = useContext(PageContext);
	const myDataSpacesViewContext = useContext(MyDataSpacesViewContext);
	const createEditDataSpaceContext = useContext(CreateEditDataSpaceContext);
	const userProfile = useContext(UserProfileContext)[0];
	const communityDataSpacesContext = useContext(CommunityDataSpacesContext);

	// DataSpaces State
	const [dataSpaces, setDataSpaces] = useState();
	const [dataSpacesListTabsValue, setDataSpacesListTabsValue] = useState('Private DataSpaces');
	const [featuredCommunityDataSpaces, setFeaturedCommunityDataSpaces] = useState();
	const [selectedDataSpace, setSelectedDataSpace] = useState();
	const [isDataSpacePreviewDialogOpen, setIsDataSpacePreviewDialogOpen] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');

	// Populate DataSpaces
	const populateDataSpaces = async () => {
		const res = await getDataSpaces(null, userProfile._id);
		setDataSpaces(res);
	};
	useEffect(() => {
		if (userProfile._id) {
			populateDataSpaces();
		}
	}, [userProfile._id]);

	// DataSpace Naming Dialog
	const [namingDialogOpen, setNamingDialogOpen] = useState(false);
	const handleNamingDialogClose = async (didSubmit) => {
		if (!didSubmit) setNamingDialogOpen(false);

		if (didSubmit) {
			// Check if name is valid
			// Based on validity, display error message or proceed
			const { valid, errorMessage } = verifyDataSpaceName(props.dataSpaceName);
			if (!valid) {
				displayError(errorMessage);
				return;
			}

			setNamingDialogOpen(false);

			// Create new DataSpace schema and save ID of database entry
			const result = await createDataSpaceSchemaWithName(localStorage.getItem('jwt'), props.dataSpaceName);
			props.handleChangeDataSpaceId(result);
			myDataSpacesViewContext.changeViewHandler('createNewDataSpace');
		}
	};

	/*
  Determine if valid dataspace name has been entered.
  @param submittedName {String} : The user's DataSpace name input
  @returns : {
    valid {boolean} : If the name is valid
    errorMessage {String} : Message to display to user if error
  }
  */
	const verifyDataSpaceName = (submittedName) => {
		// Check is not an empty string
		if (submittedName === '') {
			return {
				valid: false,
				errorMessage: 'Please enter a name for your DataSpace.',
			};
		}

		// Check is duplicate
		if (dataSpaces.find((ds) => ds.dataSpaceName === submittedName)) {
			return {
				valid: false,
				errorMessage: 'You already have a DataSpace with this name. Please choose another name for your DataSpace.',
			};
		}

		return { valid: true };
	};

	const displayError = (errorMessage) => {
		setSnackMessage(errorMessage);
		setSnackOpen(true);
	};

	// Delete DataSpace Confirmation Dialog
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [selectedListItemDataSpace, setSelectedListItemDataSpace] = useState();
	const [dataSpaceNameForDeletion, setDataSpaceNameForDeletion] = useState();
	const handleDeleteDialogClose = () => setDeleteDialogOpen(false);
	const handleDeleteDialogOpen = () => {
		// Get DataSpace name for use in dialog
		const dataSpaceTemp = dataSpaces.find((ds) => {
			return ds._id === selectedListItemDataSpace._id;
		});

		setDataSpaceNameForDeletion(dataSpaceTemp.dataSpaceName || dataSpaceTemp.name);
		setDeleteDialogOpen(true);
	};

	// Handler for when user clicks "yes" on deletion confirmation dialog.
	const handleConfirmDataSpaceDeletion = async () => {
		trackEvent({
			userDetails: { userId: userProfile._id, email: userProfile.email },
			eventDetails: { types: ['Segment', 'AppInsights'], eventName: 'Delete DataSpace Confirmation Clicked' },
		});
		handleDeleteDialogClose();
		selectedListItemDataSpace.dataSpaceType
			? deleteDataSpaceFromBackend(selectedListItemDataSpace._id)
			: await deleteDataSpaceNew(selectedListItemDataSpace._id);

		await populateDataSpaces();
	};

	// Pagination
	const [page, setPage] = useState(1);
	const handlePageChange = (e, page) => setPage(page);

	const listDataSpaces = dataSpaces?.filter((dataSpace) => {
		if (dataSpacesListTabsValue === 'Community DataSpaces' && !dataSpace.isPublic) {
			return false;
		} else if (dataSpacesListTabsValue === 'Private DataSpaces' && dataSpace.isPublic) {
			return false;
		} else if (
			searchQuery &&
			!(dataSpace.name || dataSpace.dataSpaceName).toLowerCase().includes(searchQuery.toLowerCase())
		) {
			return false;
		}
		return true;
	});

	const numItems = listDataSpaces?.length;
	const numItemsPage = 5;
	const pageCount = Math.ceil(numItems / numItemsPage);
	const beginIndex = page * numItemsPage - numItemsPage;
	const endIndex = page * numItemsPage;

	// Snackbar
	const [snackOpen, setSnackOpen] = useState(false);
	const [snackMessage, setSnackMessage] = useState('');
	const [activeIndex, setActiveIndex] = useState(0);

	const configureNewDataSpaceButtonClickHandlerNew = () => {
		createEditDataSpaceContext.setCreateEditDataSpaceMode('create');
		myDataSpacesViewContext.changeViewHandler('configureNewDataSpaceNew');

		trackEvent({
			userDetails: { userId: userProfile._id, email: userProfile.email },
			eventDetails: {
				types: ['KissMetrics', 'Segment', 'Encharge', 'GA4', 'AppInsights'],
				eventName: 'WebApp_User Clicks Create New DataSpace Button',
			},
		});
	};

	const getLeagueLogo = (dataSpace) => {
		switch (dataSpace.datasets[0].tags.sport) {
			case 'NBA':
				return { logo: NBALogo, backgroundColor: '#0253A3' };
			case 'NFL':
				return { logo: NFLLogo, backgroundColor: '#013367' };
			case 'NHL':
				return { logo: NHLLogo, backgroundColor: '#000000' };
			case 'MLB':
			default:
				return { logo: MLBLogo, backgroundColor: '#102475' };
		}
	};

	// Get Featured Community DataSpaces
	useEffect(() => {
		const getCommunityDataSpaces = async () => {
			const dataSpacesRes = await getDataSpaces(undefined, undefined, true);
			setFeaturedCommunityDataSpaces(dataSpacesRes);
		};
		getCommunityDataSpaces();

		trackEvent({
			userDetails: { userId: userProfile._id, email: userProfile.email },
			eventDetails: {
				types: ['KissMetrics', 'AppInsights', 'Segment', 'GA4'],
				eventName: 'WebApp_User Visits My DataSpaces Page',
			},
		});
	}, []);

	return dataSpaces ? (
		<div className={classes.pageWrapper}>
			{dataSpaces.length ? (
				<>
					<div className={classes.myDataSpacesWrapper}>
						<h1 style={{ margin: '0', marginBottom: '1rem' }}>My DataSpaces</h1>
						<TabMenu
							style={{ marginBottom: '1rem' }}
							model={[{ label: 'Private DataSpaces' }, { label: 'Community DataSpaces' }]}
							activeIndex={activeIndex}
							onTabChange={(e) => {
								setActiveIndex(e.index);
								setDataSpacesListTabsValue(e.value.label);
							}}
						/>

						<SearchInput searchQuery={searchQuery} setSearchQuery={setSearchQuery} />

						{listDataSpaces?.length || searchQuery ? (
							<>
								{listDataSpaces.slice(beginIndex, endIndex).map((item, index) => (
									<ListItem
										key={item._id}
										dataSpace={item}
										dataSpaceId={item._id}
										type={item.dataSpaceType}
										title={item.dataSpaceName || item.name}
										handleChangeDataSpaceId={props.handleChangeDataSpaceId}
										changeDataSpaceName={props.changeDataSpaceName}
										handleDeleteDialogOpen={handleDeleteDialogOpen}
										setSelectedListItemDataSpace={setSelectedListItemDataSpace}
										setDataSpacesListTabsValue={setDataSpacesListTabsValue}
										populateDataSpaces={populateDataSpaces}
										setPage={setPage}
									/>
								))}
								<Pagination
									page={page}
									style={{ width: 'fit-content', margin: '0.5rem auto' }}
									count={pageCount}
									siblingCount={0}
									onChange={handlePageChange}
								/>
							</>
						) : dataSpacesListTabsValue === 'Private DataSpaces' ? (
							<div style={{ margin: '3rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
								<img src={CreateDataSpace} style={{ height: '12rem' }} />
								<h3 style={{ textAlign: 'center' }}>Create a DataSpace</h3>
								<p style={{ maxWidth: '40rem', textAlign: 'center', margin: '0' }}>
									You don’t have any DataSpaces created yet. Get started by clicking “Create New DataSpace”, or check
									out some of the Community DataSpaces as a starting point.
								</p>
							</div>
						) : (
							<div style={{ margin: '3rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
								<img src={ShareToCommunity} style={{ height: '12rem' }} />
								<h3 style={{ textAlign: 'center' }}>Share to the Community</h3>
								<p style={{ maxWidth: '40rem', textAlign: 'center', margin: '0' }}>
									Want to share your approach with the Community? Choose one of your Private DataSpace and select
									"Publish" to enable other users to view and interact with your DataSpace.
								</p>
							</div>
						)}
						<div id="center">
							<Button
								className={classes.button}
								variant="contained"
								color="secondary"
								margin="normal"
								onClick={configureNewDataSpaceButtonClickHandlerNew}
							>
								Create New DataSpace
							</Button>
						</div>

						<ConfirmationDialog
							open={deleteDialogOpen}
							onClose={handleDeleteDialogClose}
							handleDataSpaceDeletion={handleConfirmDataSpaceDeletion}
							dataSpaceName={dataSpaceNameForDeletion}
						/>

						<Snackbar
							open={snackOpen}
							anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
							onClose={() => {
								setSnackOpen(false);
							}}
							autoHideDuration={5000}
						>
							<Alert severity={'error'}>{snackMessage}</Alert>
						</Snackbar>
					</div>
					<ShareDataSpaceDialog populateDataSpaces={populateDataSpaces} />
				</>
			) : (
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<img src={CreateDataSpace} style={{ width: '28rem', maxWidth: '95%' }} />
					<h2 style={{ textAlign: 'center' }}>Create your first DataSpace</h2>
					<p style={{ maxWidth: '30rem', margin: '0', marginBottom: '1rem', textAlign: 'center' }}>
						Start by selecting from one of the featured Community DataSpaces below or create a DataSpace of your own.
					</p>
				</div>
			)}

			<div style={{ width: 'fit-content', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
				<h3 style={{ width: '100%', textAlign: dataSpaces.length ? 'start' : 'center' }}>
					Featured Community DataSpaces
				</h3>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'start',
						flexWrap: 'wrap',
					}}
				>
					{featuredCommunityDataSpaces ? (
						[
							...featuredCommunityDataSpaces.map((dataSpace) => {
								const { logo, backgroundColor } = getLeagueLogo(dataSpace);
								return (
									<div
										style={{ width: '11rem', marginRight: '1rem', marginBottom: '1rem' }}
										onClick={async () => {
											// Fetch the full DataSpace object from the database
											const fetchedDataSpace = (await getDataSpaces(dataSpace._id)).data.dataSpace;
											setSelectedDataSpace(fetchedDataSpace);
											communityDataSpacesContext.setSelectedDataSpace(fetchedDataSpace);
											setIsDataSpacePreviewDialogOpen(true);
										}}
										className={classes.featuredCommunityDataSpace}
									>
										<div
											style={{
												width: '100%',
												height: '7rem',
												position: 'relative',
												borderRadius: '6px',
												overflow: 'hidden',
												backgroundColor: backgroundColor,
												display: 'flex',
												justifyContent: 'center',
											}}
										>
											<img
												src={logo}
												style={{
													backgroundColor: backgroundColor,
													height: '100%',
												}}
											/>
											<svg
												style={{ position: 'absolute', top: '0', right: '0', fill: '#FF7A00' }}
												height="60"
												width="60"
											>
												<polygon points="0,0 60,0 60,60" />
											</svg>
											<svg
												style={{ position: 'absolute', top: '5px', right: '5px', fill: '#fff' }}
												width="25"
												height="25"
											>
												<polygon points="10,0 15,0 15,10 25,10 25,15 15,15 15,25 10,25 10,15 0,15 0,10 10,10" />
											</svg>
										</div>
										<p
											style={{
												margin: '0.5rem 0 0 0',
												maxWidth: '100%',
												wordBreak: 'break-word',
												whiteSpace: 'normal',
											}}
										>
											{dataSpace.publicName || dataSpace.name}
										</p>
										<p style={{ margin: '0', color: 'blue' }}>by {dataSpace.createdBy.username}</p>
									</div>
								);
							}),
							<Button
								variant="outlined"
								color="primary"
								style={{ width: '10.5rem', height: '7rem', alignSelf: 'start' }}
								onClick={() => {
									pageContext.setPage('CommunityDataSpaces');
								}}
							>
								See More +
							</Button>,
						]
					) : (
						<CircularProgress style={{ margin: 'auto' }} />
					)}
				</div>
			</div>

			{!dataSpaces.length && (
				<div className={classes.communityDataSpacesLink}>
					<span>Don't see what you're looking for? &nbsp;</span>
					<Link onClick={configureNewDataSpaceButtonClickHandlerNew}>Build your own DataSpace</Link>
				</div>
			)}

			<DataSpacePreviewDialog
				isDataSpacePreviewDialogOpen={isDataSpacePreviewDialogOpen}
				setIsDataSpacePreviewDialogOpen={setIsDataSpacePreviewDialogOpen}
			/>
		</div>
	) : (
		<div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<CircularProgress style={{ margin: 'auto' }} />
		</div>
	);
}
